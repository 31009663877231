import './About.scss'
import { motion, Variants } from 'framer-motion'

const itemVariants: Variants = {
	offscreen: {
		opacity: 0,
		y: '-100%',
	},
	onscreen: {
		opacity: 1,
		y: 0,
	},
}

const About = () => {
	return (
		<section id="about">
			<div className="about">
				<div className="content">
					<article className="stats">
						<span>
							<span className="blue">70+</span> SATISFIED CLIENTS
						</span>
						<span>
							<span className="blue">2+</span> YEARS IN BUSINESS
						</span>
					</article>

					<motion.article className="desc">
						<motion.div
							initial="offscreen"
							whileInView="onscreen"
							style={{
								position: 'relative',
								overflow: 'hidden',
								height: '100px',
							}}
							viewport={{ once: true, amount: 0.8 }}
						>
							<motion.h1
								variants={itemVariants}
								transition={{ duration: 0.4 }}
								style={{
									position: 'absolute',
									bottom: 0,
									right: 0,
								}}
							>
								ABOUT US
							</motion.h1>
						</motion.div>
						<motion.div
							initial="offscreen"
							whileInView="onscreen"
							style={{
								position: 'relative',
								overflow: 'hidden',
								height: '350px',
								maxHeight: '500px',
							}}
							viewport={{ once: true, amount: 0.8 }}
						>
							<motion.p
								variants={itemVariants}
								transition={{ duration: 0.4 }}
								style={{
									position: 'absolute',
									top: 0,
									right: 0,
								}}
							>
								Our team comprises young, creative professionals who excel in
								UX/UI graphics, programming, and brand design. We specialize in
								developing software programs and creating visually stunning
								designs that cater to our clients' needs. Our focus is on
								delivering high-quality work that exceeds expectations
							</motion.p>
						</motion.div>
					</motion.article>
				</div>
			</div>
		</section>
	)
}

export default About
