import Hero from './components/hero/Hero'
import About from './components/about/About'
import Capabilities from './components/capabilities/Capabilities'
import Portfolio from './components/portfolio/Portfolio'
import Footer from './components/footer/Footer'
import { motion, useScroll, useSpring } from 'framer-motion'
import './App.scss'
import Navbar from './components/navbar/Navbar'

function App() {
	const { scrollYProgress } = useScroll()
	const scaleY = useSpring(scrollYProgress, {
		damping: 100,
		stiffness: 1000,
	})

	return (
		<>
			<Navbar />
			<Hero />
			<About />
			<Capabilities />
			<Portfolio />
			<Footer />
			<motion.div className="progress-bar" style={{ scaleY }} />
		</>
	)
}

export default App
