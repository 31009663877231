import './Footer.scss'
import Star from '../../assets/starBlue.png'

const Footer = () => {
	return (
		<div>
			<div className="footer" id="contact">
				<div className="work">
					<img src={Star} alt="star" className="star" />
					<a href="#">LET&lsquo;S WORK</a>
					<a href="#">TOGETHER</a>
				</div>
				<div className="links">
					<a target="_blank" href="https://discord.gg/GFTxxWWWXU">
						Discord
					</a>
					<a target="_blank" href="https://www.instagram.com/allmake.pl/">
						Instagram
					</a>
					<a target="_blank" href="https://www.tiktok.com/@allmake.pl">
						TikTok
					</a>
					<a target="_blank" href="https://www.facebook.com/ALLMAKECOM">
						Facebook
					</a>
					<a target="_blank" href="mailto:contact@all-make.com">
						contact@all-make.com
					</a>
				</div>
			</div>

			<div className="rights">
				<span className="allmake">ALL-MAKE.COM</span>
				<span className="copyright">
					COPYRIGHT&nbsp;
					{2023 === new Date().getFullYear()
						? '2023'
						: `2023-${new Date().getFullYear()}`}
				</span>
			</div>
		</div>
	)
}

export default Footer
