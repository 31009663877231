import './Portfolio.scss'
import 'swiper/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import Slider from './Slider/Slider'
import { useState } from 'react'
import { motion, Variants } from 'framer-motion'
import gsap from 'gsap'

const itemVariants: Variants = {
	offscreen: {
		opacity: 0,
		y: '-100%',
	},
	onscreen: {
		opacity: 1,
		y: 0,
	},
}

const Portfolio = () => {
	const [slider, setSlider] = useState(<Slider type={'web'} />)

	const changeType = async (
		type: 'web' | 'graphic' | 'development' | '3d' | 'videos',
	) => {
		document.querySelectorAll('.types span').forEach((item) => {
			item.classList.remove('active')
			if (item.innerHTML.toLowerCase().includes(type))
				item.classList.add('active')
		})

		gsap.to('.swiper-slide', {
			opacity: 0,
			duration: 0.3,
		})
		await gsap.to('.options', {
			opacity: 0,
			duration: 0.3,
		})
		setSlider(<Slider type={type} />)
		gsap.to('.swiper-slide', {
			opacity: 1,
			duration: 0.3,
		})
		await gsap.to('.options', {
			opacity: 1,
			duration: 0.3,
		})
	}

	return (
		<motion.div
			className="portfolio"
			id="portfolio"
			initial="offscreen"
			whileInView="onscreen"
			viewport={{ once: true, amount: 0.3 }}
			style={{
				overflow: 'hidden',
			}}
		>
			<motion.h1 variants={itemVariants}>OUR PORTFOLIO</motion.h1>
			<motion.div className="types" variants={itemVariants}>
				<span className="active" onClick={() => changeType('web')}>
					WEB & APPS
				</span>
				{/*eslint-disable-next-line*/}
				<span>//</span>
				<span onClick={() => changeType('graphic')}>GRAPHIC DESIGN</span>
				{/*eslint-disable-next-line*/}
				<span>//</span>
				<span onClick={() => changeType('development')}>DEVELOPMENT</span>
				{/*eslint-disable-next-line*/}
				<span>//</span>
				<span onClick={() => changeType('3d')}>3D</span>
				{/*eslint-disable-next-line*/}
				<span>//</span>
				<span onClick={() => changeType('videos')}>VIDEOS & ANIMATION</span>
			</motion.div>

			{slider}

			<div className="options">
				<span className="swiper-button-prev">
					<FontAwesomeIcon icon={faChevronLeft} />
				</span>
				<div className="swiper-pagination"></div>
				<span className="swiper-button-next">
					<FontAwesomeIcon icon={faChevronRight} />
				</span>
			</div>
		</motion.div>
	)
}

export default Portfolio
