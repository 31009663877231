import './Capability.scss'
import CapabilityType from '../../../types/Capability'
import gsap from 'gsap'
import { useEffect } from 'react'

interface CapabilityProps {
	capability: CapabilityType
	index: number
}

const Capability = ({ capability, index }: CapabilityProps) => {
	let closed = true

	useEffect(() => {
		const desc = document
			.getElementById(index.toString())
			?.getElementsByClassName('desc')[0] as HTMLElement

		gsap.to(desc, {
			height: 0,
			duration: 0.5,
			ease: 'power2.inOut',
		})
	}, [index])

	const toggle = () => {
		const desc = document
			.getElementById(index.toString())
			?.getElementsByClassName('desc')[0] as HTMLElement
		const button = document
			.getElementById(index.toString())
			?.getElementsByClassName('circle-plus')[0] as HTMLElement

		if (closed) {
			gsap.to(desc, {
				height: 'auto',
				duration: 0.5,
				ease: 'power2.inOut',
			})
			button.classList.add('opened')
			closed = false
		} else {
			gsap.to(desc, {
				height: 0,
				duration: 0.5,
				ease: 'power2.inOut',
			})
			button.classList.remove('opened')
			closed = true
		}
	}

	return (
		<div className="capabilityItem" id={index.toString()} onClick={toggle}>
			<div className="title">
				<span>{capability.title}</span>
				<div className="circle-plus closed">
					<div className="circle">
						<div className="horizontal"></div>
						<div className="vertical"></div>
					</div>
				</div>
			</div>
			<div className="desc">
				<p>{capability.description}</p>
			</div>
		</div>
	)
}

export default Capability
