import './Capabilities.scss'
import Star from '../../assets/star.png'
import Capability from './capability/Capability'
import CapabilityType from '../../types/Capability'
import { motion, Variants } from 'framer-motion'

const capabilities: CapabilityType[] = [
	{
		title: 'WEBSITES',
		description:
			"Our talented web developers are here to create stunning websites for your business. We specialize in designing and building websites that capture your unique brand and captivate your audience. Whether you're starting from scratch or looking to upgrade, we've got you covered. Trust our friendly team of web developers to elevate your online presence and make a lasting impression. Let's bring your vision to life and create a website that truly represents your business.",
	},
	{
		title: 'GRAPHIC DESIGN',
		description:
			'Our graphic design services bring your brand to life! Our skilled team creates eye-catching visuals, including logos, branding materials, and social media graphics. We work closely with you to understand your style and vision, delivering impressive designs that make a strong impact. Stand out from the competition with our graphic design services. Get started today and elevate your brand!',
	},
	{
		title: 'DEVELOPMENT',
		description:
			'We specialize in professional script development for the FiveM platform and offer comprehensive software programming services. From custom scripts for FiveM to tailored software solutions, we bring your ideas to life. Trust us to deliver exceptional results.',
	},
	{
		title: '3D ART',
		description:
			'We provide professional 3D art services to bring your visions to life! Our expert team specializes in creating captivating 3D visuals using advanced software tools. Whether you need 3D modeling, animation, or other custom 3D artwork, we have the skills to deliver exceptional results. Trust us to transform your ideas into stunning 3D artworks.',
	},
	{
		title: 'VIDEOS & ANIMATION',
		description:
			'We offer professional video editing and motion design services. Our expert team transforms raw footage into captivating videos. From seamless editing to dynamic motion graphics, we bring your vision to life. Elevate your visual content with our high-quality services.',
	},
]

const itemVariants: Variants = {
	offscreen: {
		opacity: 0,
		y: '-100%',
	},
	onscreen: {
		opacity: 1,
		y: 0,
	},
}

const Capabilities = () => {
	return (
		<div className="capabilities" id="capabilities">
			<motion.div
				className="header"
				initial="offscreen"
				whileInView="onscreen"
				style={{
					position: 'relative',
					overflow: 'hidden',
					height: '140px',
				}}
				viewport={{ once: true, amount: 0.8 }}
			>
				<img alt="star" src={Star} />
				<motion.h1
					variants={itemVariants}
					transition={{ duration: 0.4 }}
					style={{
						position: 'absolute',
						bottom: 0,
						right: 0,
					}}
				>
					OUR CAPABILITIES
				</motion.h1>
			</motion.div>

			<div className="capabilitiesList">
				{capabilities.map((capability, i) => (
					<Capability key={i} capability={capability} index={i} />
				))}
			</div>
		</div>
	)
}

export default Capabilities
