import './Hero.scss'
import Rectangle from '../../assets/rectangle_hero.png'
import Star from '../../assets/star.png'
import ArrowUpRight from '../../assets/arrowUpRight.png'
import { motion } from 'framer-motion'

const Hero = () => {
	return (
		<header className="hero" id="hero">
			<motion.img
				alt="rectangle"
				src={Rectangle}
				className="rectangle"
				initial={{ height: '0%' }}
				animate={{ height: '100%' }}
				transition={{ duration: 1, delay: 0.5 }}
			/>
			<img alt="star" src={Star} className="star" />

			<div className="content">
				<div className="inscription">
					<span className="since">SINCE 2022</span>
					<span className="title">MAKE FUTURE</span>
					<span className="subtitle">
						<span className="with">WITH US</span>
						<span className="small">
							LET’S CREATE SOMETHING <br />
							BIG TOGETHER WITH <br />
							FULL CREATIVITY
						</span>
					</span>
				</div>

				<div className="buttons">
					<a href="#portfolio" className="portfolioButton">
						<span>PORTFOLIO</span>
						<img alt="up right arrow" src={ArrowUpRight} />
					</a>
					<a href="#contact" className="talkButton">
						<span>LET&lsquo;S TALK</span>
						<img alt="up right arrow" src={ArrowUpRight} />
					</a>
				</div>
			</div>
		</header>
	)
}

export default Hero
