import './Navbar.scss'
import Logo from '../../assets/logo.png'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import ArrowUpRight from '../../assets/arrowUpRight.png'

const links = [
	{ name: 'About us', link: '#about' },
	{ name: 'Capabilities', link: '#capabilities' },
	{ name: 'Portfolio', link: '#portfolio' },
	{ name: 'Contact', link: '#contact' },
]

const Navbar = () => {
	const [isMenuClicked, setIsMenuClicked] = useState(true)

	const handleResize = () => {
		document.getElementById('hero')?.style.setProperty('opacity', '1')
		document.body.style.height = 'auto'
		document.body.style.overflow = 'auto'

		if (window.innerWidth < 1000) setIsMenuClicked(false)
		else setIsMenuClicked(true)
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
	}, [])

	const updateMenu = () => {
		if (window.innerWidth < 1000) {
			setIsMenuClicked(!isMenuClicked)

			if (!isMenuClicked) {
				document.body.style.height = '100vh'
				document.body.style.overflow = 'hidden'
				document.getElementById('hero')?.style.setProperty('opacity', '0.5')
			} else {
				document.body.style.height = 'auto'
				document.body.style.overflow = 'auto'
				document.getElementById('hero')?.style.setProperty('opacity', '1')
			}
		}
	}

	return (
		<nav className="navbar">
			<div className="logo">
				<img src={Logo} />
				<span>ALLMAKE</span>
			</div>

			<AnimatePresence>
				{isMenuClicked && (
					<motion.div
						className="links"
						initial={{
							x: 200,
							opacity: 0,
							y: 0,
						}}
						animate={
							window.innerWidth > 999
								? {
										x: 0,
										opacity: 1,
								  }
								: {
										x: 0,
										opacity: 1,
								  }
						}
						exit={{
							x: 200,
							opacity: 0,
						}}
						transition={{
							duration: 0.5,
						}}
					>
						{links.map((link, i) => (
							<a key={i} href={link.link} onClick={updateMenu} className="link">
								{link.name}
								<div className="line"></div>
							</a>
						))}
						{window.innerWidth < 1000 && (
							<div className="buttons">
								<a target="_blank" href="https://discord.gg/GFTxxWWWXU">
									DISCORD
									<img alt="up right arrow" src={ArrowUpRight} />
								</a>
								<a target="_blank" href="https://www.instagram.com/allmake.pl/">
									INSTAGRAM
									<img alt="up right arrow" src={ArrowUpRight} />
								</a>
								<a target="_blank" href="https://www.facebook.com/ALLMAKECOM">
									FACEBOOK
									<img alt="up right arrow" src={ArrowUpRight} />
								</a>
							</div>
						)}
					</motion.div>
				)}
			</AnimatePresence>

			<div className="burger-menu" onClick={updateMenu}>
				<div
					className={
						'burger-bar' + (!isMenuClicked ? ' unclicked' : ' clicked')
					}
				></div>
				<div
					className={
						'burger-bar' + (!isMenuClicked ? ' unclicked' : ' clicked')
					}
				></div>
			</div>
		</nav>
	)
}

export default Navbar
